import { Box, styled, Typography } from '@material-ui/core'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import WhyTriTagImg from '../../images/About/why-tri-tag.png'

const WhyTriTagContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column',
      },
    }
  }}
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
`
const StyledWhyTriTagImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        height: '900px',
      },
      [theme.breakpoints.down(1600)]: {
        height: '800px',
      },
      [theme.breakpoints.down(1400)]: {
        height: '750px',
      },
      [theme.breakpoints.down(1230)]: {
        height: '700px',
      },
      [theme.breakpoints.down(1200)]: {
        height: '500px',
        marginRight: '1rem',
        borderRadius: '1rem',
      },
    }
  }}
  height: 900px;
  object-fit: cover;
`
const WhyTriTagTextContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {},
      [theme.breakpoints.down(1400)]: {},
      [theme.breakpoints.down(1230)]: {},
      [theme.breakpoints.down(1200)]: {
        margin: 'auto',
        width: '95%',
      },
    }
  }}
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 4%;
  width: 80%;
`
const WhyTriTagTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down(1650)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.down(1230)]: {
        fontSize: '0.8rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '0.9rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}

  font-size: 1.2rem;
  color: #3c3835;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
`
const WhyTriTagLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.4rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}
  margin-top: 2rem;
  font-weight: bold;
  font-size: 1.5rem;
  color: #008174;
  margin-bottom: 2rem;
`

export default function WhyTriTag() {
  return (
    <WhyTriTagContainer>
      <StyledWhyTriTagImg src={WhyTriTagImg} alt="logo" placeholder="blurred" />
      <WhyTriTagTextContainer>
        <WhyTriTagLabelTypography>Why Tri Tag?</WhyTriTagLabelTypography>
        <WhyTriTagTypography sx={{ marginBottom: '2rem' }}>
          We believe there needs to be an alternative to contact rugby for:
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          parents concerned about their sons or daughters suffering head knocks
          or injury
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          players that don’t like contact
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          those that are unable to play contact rugby due to the risk of missing
          out on work if they sustain an injury and
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          those wanting to participate in a unisex format
        </WhyTriTagTypography>
        <WhyTriTagTypography sx={{ marginBottom: '2rem', marginTop: '3rem' }}>
          We also believe there needs to be a game that allows participants to
          choose when and if they play contact rugby, without missing out on the
          appropriate knowledge and skill development should they eventually do
          so.
        </WhyTriTagTypography>
        <WhyTriTagTypography sx={{ marginBottom: '2rem', marginTop: '3rem' }}>
          In summary the game aims to provide an entry point for, and appeal to,
          regardless of age, gender or race
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          the more serious or aspirational participant seeking rugby knowledge
          and skill development should they wish to transition to contact rugby
          at some point, and
        </WhyTriTagTypography>
        <WhyTriTagTypography>
          <ChevronRightIcon color="primary" sx={{ fontSize: '2rem' }} />
          the social participant seeking a fun, social, inclusive game that
          brings people and communities together.
        </WhyTriTagTypography>
      </WhyTriTagTextContainer>
    </WhyTriTagContainer>
  )
}

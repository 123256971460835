import { Box, styled, Typography } from '@material-ui/core'
import TechPlatFormImg from '../../images/About/ttr-tech-platform.png'

const TechPlatFormContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column',
        marginTop: '3rem',
      },
    }
  }}
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  margin-top: 3.5rem;
`
const StyledTechPlatFormImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        height: '750px',
      },
      //   [theme.breakpoints.down(1600)]: {
      //     height: '800px',
      //   },
      [theme.breakpoints.down(1400)]: {
        height: '700px',
      },
      [theme.breakpoints.down(1230)]: {
        height: '700px',
      },
      [theme.breakpoints.down(1200)]: {
        height: '450px',
        marginRight: '1rem',
        borderRadius: '1rem',
      },
    }
  }}

  height: 850px;
  object-fit: cover;
`
const TechPlatFormTextContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        marginTop: '0',
      },
      [theme.breakpoints.down(1400)]: {},
      [theme.breakpoints.down(1230)]: {},
      [theme.breakpoints.down(1200)]: {
        margin: 'auto',
        width: '95%',
      },
    }
  }}
  margin-top: 2rem;
  margin-right: 1rem;
  margin-left: 2.5%;
  width: 80%;
`
const TechPlatFormTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        fontSize: '1.1rem',
      },
      [theme.breakpoints.down(1650)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1600)]: {
        fontSize: '1rem',
      },
      [theme.breakpoints.down(1400)]: {
        fontSize: '0.9rem',
      },
      [theme.breakpoints.down(1230)]: {
        fontSize: '0.85rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '0.9rem',
        marginLeft: '1rem',
        marginRight: '1rem',
      },
    }
  }}

  font-size: 1.2rem;
  color: #3c3835;
  margin-bottom: 0.5rem;
  vertical-align: middle;
  display: flex;
  align-items: center;
`
const TechPlatFormLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1700)]: {
        marginTop: '0.2rem',
      },
      [theme.breakpoints.down(1652)]: {
        fontSize: '1.5rem',
      },
      [theme.breakpoints.down(1200)]: {
        textAlign: 'left',
        fontSize: '1.4rem',
        marginLeft: '1rem',
        marginRight: '1rem',
        marginTop: '1.5rem',
      },
    }
  }}
  font-weight: bold;
  font-size: 1.5rem;
  color: #008174;
  margin-bottom: 2rem;
`

export default function TechPlatForm() {
  return (
    <TechPlatFormContainer>
      <StyledTechPlatFormImg
        src={TechPlatFormImg}
        alt="logo"
        placeholder="blurred"
      />
      <TechPlatFormTextContainer>
        <TechPlatFormLabelTypography>
          TTR tech platform
        </TechPlatFormLabelTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          TTR’s tech platform has allowed us to streamline, simplify and make
          more convenient the processes associated with conducting and
          participating in an amateur sport.
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          Players are able to search for their closest competition, register,
          make payment, receive timely information via alerts (forfeits, weather
          alerts etc.), access their draw, stats and ladder standings, along
          with news and information. They can also design and order their
          uniform, communicate with those conducting the competition and access
          educational material.
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          Competition managers receive registrations and can then easily create
          draws with the automated draw builder. They still have the luxury to
          make manual adjustments though if they wish. They can also organise
          referees, communicate with participants, publish scores, results & try
          scorers and report injury’s & incidents in the unlikely event they
          occur.
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          The best part is this can all be done from their loungeroom!
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          We also aim to provide participants and or their parents access to
          information that is both helpful and insightful to them and or their
          child.
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          One way we achieve this is through Kids Korner. Kids Korner is a
          designated kids section within the tech platform providing fun videos
          that deliver key messages via animated cartoon characters. These
          characters educate children on the rules of Tri Tag Rugby, along with
          providing nutritional and safety information.
        </TechPlatFormTypography>
        <TechPlatFormTypography sx={{ marginBottom: '2rem' }}>
          There is also content for parents regarding snack and lunchbox ideas,
          pre & post game meals, and general nutritional guidelines for their
          children.
        </TechPlatFormTypography>
      </TechPlatFormTextContainer>
    </TechPlatFormContainer>
  )
}

import { Box, styled } from '@material-ui/core'
import ReactPlayer from 'react-player'
import { useMediaQuery } from 'react-responsive'
import NoSSR from 'react-no-ssr'
import Apparel from './apparel'
// import OverView from './overview'
import Partners from './partners'
// import Sustainable from './sustainable'
import TechPlatForm from './tech-platform'
import WhyTriTag from './why-tri-tag'
import image from '../../images/210917_TRI TAG RUGBY_0720.jpg'

const PlayerWrapper = styled(Box)`
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 3.5rem;
  .react-player__preview {
    background-image: url('${image}');
  }
`

export default function AboutContent() {
  const isXLarge = useMediaQuery({ minWidth: 1701 })
  const isLarge = useMediaQuery({ maxWidth: 1700, minWidth: 1400 })
  const isMedium = useMediaQuery({ maxWidth: 1400, minWidth: 1300 })
  const is1300 = useMediaQuery({ maxWidth: 1300, minWidth: 1200 })
  const is1200 = useMediaQuery({ maxWidth: 1200, minWidth: 500 })
  const isSmall = useMediaQuery({ maxWidth: 500 })

  const playerWidth = () => {
    if (isXLarge) {
      return '88vw'
    }
    if (isLarge) {
      return '88vw'
    }
    if (isMedium) {
      return '88vw'
    }
    if (is1300) {
      return '96vw'
    }
    if (is1200) {
      return '96vw'
    }
    if (isSmall) {
      return '96vw'
    }
  }

  const playerHeight = () => {
    if (isXLarge) {
      return '49.5vw'
    }
    if (isLarge) {
      return '49.5vw'
    }
    if (isMedium) {
      return '49.5vw'
    }
    if (is1300) {
      return '54vw'
    }
    if (is1200) {
      return '54vw'
    }
    if (isSmall) {
      return '54vw'
    }
  }

  return (
    <>
      <Box display="flex" justifyContent="center" />
      {/* <OverView /> */}
      <NoSSR>
        <PlayerWrapper width={playerWidth()} height={playerHeight()}>
          <ReactPlayer
            style={{ margin: 'auto' }}
            light
            controls
            playing
            loop
            width={playerWidth()}
            height={playerHeight()}
            url="https://tritagrugbypublic.s3.ap-southeast-2.amazonaws.com/ttr_low_res.mp4"
          />
        </PlayerWrapper>
      </NoSSR>
      <WhyTriTag />
      <Apparel />
      <TechPlatForm />
      {/* <Sustainable /> */}
      <Partners />
    </>
  )
}

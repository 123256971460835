import { Box, Typography, styled } from '@material-ui/core'
import { StaticImage } from 'gatsby-plugin-image'
import wavyImg from '../../images/About/wavy.png'

const StyledLabelTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        width: '100%',
        lineHeight: '2',
        padding: '1rem 1rem',
        paddingTop: '5rem',
      },
    }
  }}
  font-weight: bolder;
  font-size: 2rem;
  color: #fff;
  margin-top: 3rem;
  margin-bottom: 2rem;
`

const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        width: '100%',
        padding: '1rem 1rem',
      },
    }
  }}
  font-weight: bolder;
  font-size: 1rem;
  color: #fff;
  margin-top: 1rem;
  margin-bottom: 2rem;
  text-align: left;
`

const StyledContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1230)]: {
        paddingRight: 0,
        paddingLeft: 0,
        backgroundColor: '#f5821f',
        clipPath: 'polygon(0 12%, 100% 20%, 100% 100%, 0% 100%)',
      },
      [theme.breakpoints.down(1200)]: {
        paddingRight: 0,
        paddingLeft: 0,
        flexDirection: 'column',
        marginTop: '0',
      },
    }
  }}
  text-align: center;
  background: url(${wavyImg});
  background-size: cover;
  background-repeat: no-repeat;
  padding: 5rem 5rem;
  padding-bottom: 2rem;
  margin: auto;
  margin-top: 3.5rem;
  width: 100%;
`

const PartnersContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1230)]: {},
      [theme.breakpoints.down(1200)]: {
        flexDirection: 'column',
      },
    }
  }}
  display: flex;
  justify-content: center;
`

const PartnerOne = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1230)]: {
        marginRight: 0,
      },
      [theme.breakpoints.down(1200)]: {
        marginRight: 0,
      },
    }
  }}
  margin-right: 6rem;
`

export default function Partners() {
  return (
    <StyledContainer>
      <StyledLabelTypography>OUR PARTNERS</StyledLabelTypography>
      <PartnersContainer>
        <PartnerOne>
          <StaticImage
            src="../../images/About/Partners/BJ.png"
            alt="logo"
            height={250}
            placeholder="blurred"
          />
        </PartnerOne>
        <Box>
          <StaticImage
            src="../../images/About/Partners/BW_LOGO_NOBG.png"
            alt="logo"
            height={250}
            placeholder="blurred"
          />
        </Box>
      </PartnersContainer>

      <StyledTypography>
        If you are interested in corporate partnerships with Tri Tag Rugby, send
        us an email at info@tritagrugby.com
      </StyledTypography>
    </StyledContainer>
  )
}

import { Box, Typography, styled } from '@material-ui/core'
import apparelImg from '../../images/About/triangle.png'

const StyledTypography = styled(Typography)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        width: '100%',
        lineHeight: '2',
        padding: '1rem 1rem',
      },
    }
  }}
  font-weight: bolder;
  font-size: 1.7rem;
  color: #fff;
  padding: 7rem 5rem;
  width: 70%;
`

const StyledApparelImg = styled('img')`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1200)]: {
        marginBottom: '1rem',
        marginRight: '1rem',
      },
    }
  }}
  align-self: flex-end;
  margin-bottom: 1.2rem;
  margin-right: 3rem;
  height: 110px;
  object-fit: cover;
`

const StyledContainer = styled(Box)`
  ${({ theme }) => {
    return {
      [theme.breakpoints.down(1230)]: {
        paddingRight: 0,
        paddingLeft: 0,
      },
      [theme.breakpoints.down(1200)]: {
        paddingRight: 0,
        paddingLeft: 0,
        flexDirection: 'column',
        marginTop: '3rem',
      },
    }
  }}
  display: flex;
  justify-content: space-between;
  background: #008174;
  margin-top: 5rem;
  padding-right: 5rem;
  padding-left: 5rem;
`

export default function Apparel() {
  return (
    <StyledContainer>
      <StyledTypography>
        Tri Tag Rugby apparel is proudly made in Australia by Australians
        supporting the local economy and creating Aussie jobs
      </StyledTypography>

      <StyledApparelImg src={apparelImg} alt="logo" placeholder="blurred" />
    </StyledContainer>
  )
}
